import { authRoles } from 'app/auth';
import { lazy } from 'react';

const DataAppConfig = {
	settings: {
		layout: {}
	},
	auth: [...authRoles.admin, ...authRoles.productModerator, ...authRoles.warehouseMan],
	routes: [
		{
			path: '/apps/warehouses/:dataID',
			component: lazy(() => import('./single/Data'))
		},
		{
			path: '/apps/warehouses',
			component: lazy(() => import('./list/Datas'))
		}
	]
};

export default DataAppConfig;
