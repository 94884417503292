import { lazy } from 'react';
import { authRoles } from 'app/auth';

const PartnersAppConfig = {
	settings: {
		layout: {}
	},
	auth: [
		...authRoles.admin,
		...authRoles.productModerator,
		...authRoles.diller,
		...authRoles.salesAgent,
		...authRoles.warehouseMan
	],
	routes: [
		{
			path: '/apps/report/product-report',
			component: lazy(() => import('./product-report/ProductReport'))
		},
		{
			authRoles: [authRoles.admin],
			path: '/apps/report/admin-report',
			component: lazy(() => import('./admin-report/AdminReport'))
		},
		{
			path: '/apps/report/order-report/:dataID/operation/:operationType',
			exact: true,
			component: lazy(() => import('./order-report/single/tabs/LogHistoryDetailed'))
		},
		{
			//if operationType is INPUT_FROM_DEALER_TO_SHOP || INPUT_FROM_MODERATOR_TO_SHOP || SALE_FROM_SHOP
			path: '/apps/report/order-report/:dataID/operation-grouped/:operationType',
			exact: true,
			component: lazy(() => import('./order-report/single/tabs/LogHistoryDetailedGrouped'))
		},
		{
			// if operationType is INPUT_FROM_ANOTHER_DEALER
			path: '/apps/report/order-report/:dataID/operation-grouped-for-return/:operationType',
			exact: true,
			component: lazy(() => import('./order-report/single/tabs/LogHistoryDetailedGroupedForReturn'))
		},
		{
			path: '/apps/report/order-report/:dataID', // second page
			component: lazy(() => import('./order-report/single/Data'))
		},
		{
			path: '/apps/report/order-report', // first page
			component: lazy(() => import('./order-report/OrderReport'))
		},
		{
			authRoles: [authRoles.salesAgent],
			path: '/apps/report/analytics-sa',
			component: lazy(() => import('./analytics-sa/Datas'))
		},
		{
			path: '/apps/report/analytics-diller/:dataID',
			component: lazy(() => import('./analytics-diller/single/Datas'))
		},
		{
			path: '/apps/report/analytics-diller',
			component: lazy(() => import('./analytics-diller/asistents/Datas'))
		}
	]
};

export default PartnersAppConfig;
